<template>
  <div>
    <el-dialog
      :lock-scroll="true"
      v-loading.fullscreen="loaders.modal"
      title="Add New Patient"
      :visible.sync="dialogVisible"
      width="720px"
      :before-close="handleClose"
    >
      <validation-observer v-slot="{handleSubmit}" ref="addPatientRef">
        <form class="add-patient" role="form" @submit.prevent="handleSubmit(onSubmit)">

          <div class="scrollable height-fixed">
            <div class="row mr-0">
              <div class="col-md-6">
                <base-input
                  :rules="{required: true}"
                  name="first name"
                  label="First Name*"
                  placeholder="First Name"
                  v-model="form.first_name"
                />
              </div>

              <div class="col-md-6">
                <base-input
                  :rules="{required: true}"
                  name="last name"
                  label="Last Name*"
                  placeholder="Last Name"
                  v-model="form.last_name"
                />
              </div>
            </div>

            <div class="row mr-0">
              <div class="col-md-6">
                <base-input
                  name="email"
                  label="Email"
                  type="email"
                  placeholder="Email"
                  v-model="form.email"
                />
              </div>

              <div class="col-md-6">
                <base-input
                  name="phone"
                  v-mask="{mask: '999-999-9999'}"
                  label="Phone"
                  placeholder="Phone"
                  v-model="form.phone"
                />
              </div>
            </div>

            <div class="row mr-0">
              <div class="col-md-6">
                <p class="mb-2 font-weight-600 custom-label">Height*</p>
                <div class="row">
                  <div class="col-xs-12 col-md-6">
                    <base-input name="feet" :rules="{required: true}">
                      <el-select
                        prop="feet"
                        class="w-100 select-primary pagination-select"
                        v-model="form.feet"
                        :rules="{required: true}"
                        name="feet"
                        placeholder="FEET"
                      >
                        <el-option
                          class="select-primary"
                          v-for="item in dropdowns.feet"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </base-input>
                  </div>
                  <div class="col-xs-12 col-md-6">
                    <base-input name="inches" :rules="{required: true}">
                      <el-select
                        prop="inches"
                        class="w-100 select-primary pagination-select"
                        v-model="form.inches"
                        :rules="{required: true}" name="inches"
                        placeholder="INCHES"
                      >
                        <el-option
                          class="select-primary"
                          v-for="item in dropdowns.inches"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </base-input>
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <p class="mb-2 font-weight-600 custom-label">DOB*</p>
                <base-input name="dob" :rules="{required: true}">
                  <div>
                    <b-input-group class="mb-3">
                      <el-input style="height: 40px; width: calc(100% - 60px); border-top-right-radius: 0px;border-bottom-right-radius: 0px"
                                v-mask="'##/##/####'"
                                v-model="form.dob"
                                type="text"
                                placeholder="MM/DD/YYYY"
                                autocomplete="off"
                                @blur="handleDob"
                      ></el-input>
                      <b-input-group-append>
                        <b-form-datepicker
                          min="1930-01-01"
                          button-only
                          right
                          locale="en-US"
                          aria-controls="example-input"
                          :date-format-options="{ month: '2-digit' ,day: '2-digit', year: 'numeric' }"
                          @context="onContextDob"
                        ></b-form-datepicker>
                      </b-input-group-append>
                    </b-input-group>
                  </div>
                </base-input>
              </div>
            </div>

            <div class="row mr-0 mt-2">
              <div class="col-xs-12 col-md-6 mb-3">
                <label class="form-control-label">Stage</label>
                <el-select
                  class="w-100 select-primary pagination-select"
                  v-model="form.stage"
                  placeholder="Initial Stage"
                >
                  <template v-for="(item,index) in dropdowns.stages">
                    <el-option
                      v-if="index!==0"
                      class="select-primary"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </template>

                </el-select>
              </div>

              <div class="col-xs-12 col-md-6 mb-3">
                <p class="mb-2 font-weight-600 custom-label">Surgery Date</p>
                <div>
                  <b-input-group class="mb-3">
                    <el-input style="height: 40px; width: calc(100% - 60px); border-top-right-radius: 0px;border-bottom-right-radius: 0px"
                              v-mask="'##/##/####'"
                              id="example-input"
                              v-model="form.surgery_date"
                              type="text"
                              placeholder="MM/DD/YYYY"
                              autocomplete="off"
                              @blur="handleSurgeryDate"
                    ></el-input>
                    <b-input-group-append>
                      <b-form-datepicker
                        :min="today"
                        button-only
                        right
                        locale="en-US"
                        aria-controls="example-input"
                        :date-format-options="{ month: '2-digit' ,day: '2-digit', year: 'numeric' }"
                        @context="onContextSurgeryDate"
                      ></b-form-datepicker>
                    </b-input-group-append>
                  </b-input-group>
                </div>
              </div>

              <div class="col-xs-12 col-md-6">
                <base-input
                  label="MRN Number (Optional)"
                  placeholder="MRN Number (Optional)"
                  v-model="form.mrn_number"
                  :maxlength="16"
                />
              </div>

              <div v-for="(category,index) in categories" class="col-xs-12 col-md-6">

                <p class="mb-2 font-weight-600 custom-label ellipsis-overflow-text">{{ category.name }}</p>
                <el-select
                  class="w-100"
                  v-model="formState[`${category.id}`]"
                  placeholder="Select a Subcategory"
                  @change="prepareForm"
                  :clearable="true"
                  @clear="removeState(category.id)"
                >

                  <el-option
                    class="select-primary"
                    v-for="(item) in category.user_sub_categories"
                    :key="item.name+item.id"
                    :label="item.name"
                    :value="item.id.toString()"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>

          <div class="d-flex justify-content-between align-items-center mt-5">
              <el-checkbox
                v-model="form.sendEmail"
                class="orange-checkbox mb-0"
                :disabled="getCheckboxState">
              <span class="text-dark-blue font-weight-500">
                Email login details to patient
              </span>
              </el-checkbox>

            <div class="">
              <el-button class="cancel-btn" @click="handleClose">Cancel</el-button>
              <el-button class="move_btn" native-type="submit" type="submit">Add Patient</el-button>
            </div>
          </div>
        </form>
      </validation-observer>

    </el-dialog>
  </div>
</template>

<script>
import patientConstants from "@/constants/patientConstants";
import pickerOptions from "@/constants/pickerOptions";
import moment from "moment";

export default {
  props: ['dialogVisible', 'categories', "categoriesLoader"],
  data() {
    return {
      today: moment().format('YYYY-MM-DD'),
      loaders: {
        modal: false,
      },
      formState: {},
      form: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        feet: '',
        inches: '',
        dob: '',
        stage: '50',
        surgery_date: '',
        mrn_number: '',
        subCategories: [],
        sendEmail: false,
      },
      dropdowns: {
        stages: patientConstants.stages,
        feet: patientConstants.feet,
        inches: patientConstants.inches
      },
      pickerOptions: {
        dobPickerOptions: pickerOptions.dobPickerOptions,
        surgeryDateOptions: pickerOptions.surgeryDateOptions,
      }
    };
  },
  computed: {
    getCheckboxState() {
      return this.form.email.length <= 0;
    },
    categoriesStateEmpty() {
      return (this.categories &&
          Object.keys(this.categories).length === 0 &&
          this.categories.constructor === Object) ||
        this.categories.length <= 0
    }
  },
  watch: {
    dialogVisible() {
      if (this.$refs.addPatientRef) {
        this.$refs.addPatientRef.reset();
      }
    },
  },
  methods: {
    handleDob(e){
      if (moment(e.target.value).format('MM/DD/YYYY') == 'Invalid date'){
        this.form.dob = '';
      }
      if (moment(this.form.dob) < moment('01/01/1930')) {
        this.form.dob = '01/01/1930';
      }
    },
    handleSurgeryDate(e)
    {
      if (moment(e.target.value).format('MM/DD/YYYY') == 'Invalid date'){
        this.form.surgery_date = '';
      }
      if (moment(this.form.surgery_date) < moment(this.today)) {
        this.form.surgery_date = moment(this.today).format('MM/DD/YYYY');
      }
    },
    onContextDob(ctx) {
      if (ctx.selectedYMD != ''){
        this.form.dob = ctx.selectedFormatted
      }
    },
    onContextSurgeryDate(ctx) {
      if (ctx.selectedYMD != ''){
        this.form.surgery_date = ctx.selectedFormatted
      }
    },
    clearForm() {
      this.form = {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        feet: '',
        inches: '',
        dob: '',
        stage: '50',
        surgery_date: '',
        mrn_number: '',
        sendEmail: false,
        subCategories: [],
      };
      this.formState = {};
    },
    removeState(id) {
      delete this.formState[id]
      this.prepareForm()
    },
    prepareForm() {
      const subCategoryKeys = [
        'user_category_id', 'user_sub_category_id',
        'user_sub_category_name', 'user_category_name'
      ]
      const keys = Object.keys(this.formState)
      const count = keys.length
      const newSubcategoryArray = []
      for (let i = 0; i < count; i++) {
        newSubcategoryArray.push(
          _.zipObject(subCategoryKeys, [...[parseInt(keys[i]),
            parseInt(this.formState[`${keys[i]}`])],
            ...this.getCategoryNames(keys[i], this.formState[`${keys[i]}`])
          ])
        )
      }
      this.form.subCategories = newSubcategoryArray
    },
    getCategoryNames(id1, id2) {
      for (let i = 0; i < this.categories.length; i++) {
        if (this.categories[i].id === parseInt(id1)) {
          for (let j = 0; j < this.categories[i].sub_category.length; j++) {
            if (this.categories[i].user_sub_categories[j].id === parseInt(id2))
              return [this.categories[i].user_sub_categories[j].name, this.categories[i].name]
          }
        }
      }
      return ['', '']
    },
    onSubmit() {
      this.form.email.trim() === '' ? this.form.sendEmail = false : '';
      this.loaders.modal = true;
      this.form.surgery_date = this.form.surgery_date ? moment(this.form.surgery_date).format('YYYY-MM-DD') : '';
      if (moment(this.form.dob) < moment('01/01/1930')) {
        this.form.dob = '01/01/1930';
      }

      let th = this;
      axios.post(this.$store.getters.getBaseUrl + '/api/patients/create', this.form).then((response) => {
        th.$store.commit('toggleSessionCheck', true)
        th.$store.commit('updateSessionTime', 0)
        th.$notify.success({
          title: 'Success',
          message: 'Patient added successfully.'
        });
        th.clearForm();
        th.$emit('closeDialog')
      }).catch(error => {
        let message = 'Something went wrong please try again in few minutes.';
        if (error.response) {
          let errors = error.response.data.errors;
          let firstError = Object.keys(errors)[0];
          message = errors[firstError][0];
        }
        th.$notify.error({
          title: 'Error',
          message: message
        });
      }).finally(() => {
        this.loaders.modal = false;
      });
    },
    handleClose(done) {
      this.$emit('closeDialog')
      this.clearForm()
    },
  },
  mounted() {

  },
};
</script>

<style scoped>

.scrollable {
  overflow-y: auto;
}

.height-fixed {
  max-height: 480px;
}

.form-label {
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0;
  color: #172B4D;
}

.move_btn {
  top: 505px;
  left: 850px;
  width: 130px;
  height: 45px;
  background: #94B527 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 8px #32325D26;
  border-radius: 6px;
  border: 0px;
  color: white;
  opacity: 1;
}

.cancel-btn {
  top: 505px;
  left: 756px;
  width: 79px;
  height: 45px;
  background: #8898AA 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 8px #32325D26;
  border: 0px;
  border-radius: 6px;
  color: white;
  opacity: 1;
}

.custom-label {
  color: #525f7f;
  font-size: 0.875rem;
  font-weight: 600;
}

.checkbox-input {
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0;
  color: #172B4D;
  opacity: 1;
}

</style>
