<template>
  <div>
    <div class="modals">
      <modal :show.sync="modals.exportPatientsModal" class="checklist-export-modal">
        <div class="modal-header pb-0 pl-0 pr-0 pt-3 mt-0">
          <h2 class="text-left">Report</h2>
          <button type="button" class="close pt-4" data-dismiss="modal" aria-label="Close" @click="modals.exportPatientsModal=false">
            <span aria-hidden="true" class="text-black">&times;</span>
          </button>
        </div>
        <div class="modal-body custom pb-0 pl-0 pr-0">
          <p class="m-0 p-0 export-message">This report can take a few minutes to load. Thank you for your patience.</p>
          <div class="mt-3">
            <input type="checkbox" id="show-names" class="show-names text-center" v-model="exportPatients.includeName"/>
            <label for="show-names" class="ml-2 pt-0 export-message export-label-color"> Show Names</label>
          </div>
        </div>
        <div class="modal-footer pb-0 pl-0 pr-0">
          <div class="m-0 p-0">
            <base-button class="btn-purple pl-3 pr-3 br-6" type="secondary" @click="modals.exportPatientsModal=false">Cancel</base-button>
            <base-button class="btn-primary-action pl-4 pr-4 br-6" type="success" @click="onConfirmExportPatientsModal" :loading="loaders.exportModalLoader">
              Export
            </base-button>
          </div>
        </div>

      </modal>
    </div>
    <div class="row mx-0 pl-3 pt-4 pb-0 pr-2 justify-content-between align-items-center">
      <h1 class="ml-3 d-inline-block mt-2">Manage Patients</h1>
      <div class="d-flex justify-content-between align-items-center">
        <router-link :to="{ name: 'UserCategoryList' }">
          <base-button
            size="md"
            type="secondary"
            class="mr-3 title-btn-padding white-outline-button button-font"
          >
            Patient Tags
          </base-button>
        </router-link>
        <base-button
          size="md"
          class="mr-3 title-btn-padding white-outline-button button-font"
          @click="openGroupMessageDialog" type="secondary">
          Send Group Message
        </base-button>
        <base-button
          :disabled="$store.state.modals.downloadingReport"
          size="md"
          class="mr-3 title-btn-padding white-outline-button button-font"
          @click="openExportPatientsDialog" type="secondary">
          <img alt="Image placeholder" class="mt--1" src="/img/svg/export.svg">
          Export
        </base-button>

        <router-link class="router-link ml-2" :to="{name: 'AddPatient' }">
          <base-button
            size="md"
            class="mr-4 title-btn-padding button-font btn-add-patient color-white"
            type="secondary">
            Add Patient
          </base-button>
        </router-link>
      </div>
    </div>
    <group-message-dialog :dialogVisible="dialogVisible"
                          :categories="patientTags"
                          @closeDialog="dialogVisible=false"/>

    <base-header class="pb-6" style="height: 150px">
    </base-header>
    <div class="container-fluid mt--8">
      <div>
        <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
          <div>
            <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap mb-3"
            >
              <div class="d-flex w-85 flex-wrap">
                <base-input class="d-inline-block mr-2 manage-patient-filter" v-model="api_param.first_name"
                            label="First Name"
                            placeholder="Search..." @change="filterChanged" type="search" @search="filterChanged">
                </base-input>

                <base-input class="d-inline-block mr-2 manage-patient-filter" v-model="api_param.last_name"
                            label="Last Name"
                            placeholder="Search..." @change="filterChanged" type="search" @search="filterChanged">
                </base-input>

                <base-input class="d-inline-block mr-2 manage-patient-filter" v-model="api_param.email"
                            label="Email"
                            placeholder="Search..." @change="filterChanged" type="search" @search="filterChanged">
                </base-input>

                <base-input class="d-inline-block mr-2 manage-patient-filter" v-model="api_param.mrn_number"
                            label="MRN" maxlength="16"
                            placeholder="Search..." @change="filterChanged" type="search" @search="filterChanged">
                </base-input>
              </div>

              <div class="w-15 d-flex manage-patients-ucf-button-div filter-button-width">
                <base-button type="secondary" class="py-2 ml-auto align-self-center font-weight-normal custom-button" @click="$store.commit('showUCFS')">
                  <img class="mr-2" src="/img/svg/filters.svg" alt="Filters"> Filter <el-badge :value="getFiltersCount" class="filter-count-badge" type="warning"></el-badge>
                </base-button>
              </div>

            </div>
            <el-table :data="tableData"
                      row-key="id"
                      header-row-class-name="thead-light"
                      class="show-hidden-scrollbar"
                      v-loading="loaders.tableLoader"
                      @sort-change="sortMethod"
            >
              <el-table-column min-width="230px" align="right" label="Actions">
                <div slot-scope="{row}" class="d-flex">
                  <router-link class="router-link" :to="{name: 'checklists' , params: { id: row.id } }">
                    <el-tooltip
                      class="item" effect="dark"
                      content="Checklist"
                      placement="bottom-start"
                      :visible-arrow="false"
                    >
                    <img :src="row.checklists > 0  ? '/img/svg/checklist-assigned-gray.svg' : '/img/icons/buttons/checklist.svg'" alt="Checklist">
                    </el-tooltip>
                  </router-link>
                  <router-link class="router-link ml-2" :to="{name: 'NutritionReport' , params: { id: row.id } }">
                    <el-tooltip
                      class="item" effect="dark"
                      content="Nutrition"
                      placement="bottom-start"
                      :visible-arrow="false"
                    >
                    <img :src="row.foodLogged > 0  ? (row.foodLogged == 1  ? '/img/svg/apple-green.svg' : '/img/svg/apple-red.svg' ) : '/img/icons/buttons/apple.svg'" alt="">
                    </el-tooltip>
                  </router-link>
                  <router-link class="router-link ml-2" :to="{name: 'FoodLog' , params: { id: row.id } }">
                    <el-tooltip
                      class="item" effect="dark"
                      content="30 Days Food Log"
                      placement="bottom-start"
                      :visible-arrow="false"
                    >
                    <img src="/img/svg/foodlog-report-grey.svg" alt="">
                    </el-tooltip>
                  </router-link>
                  <router-link class="router-link ml-2" :to="{name: 'PatientDetail' , params: { id: row.id } }">
                    <el-tooltip
                      class="item" effect="dark"
                      content="View Account"
                      placement="bottom-start"
                      :visible-arrow="false"
                    >
                    <img src="/img/eye.svg" alt="">
                    </el-tooltip>
                  </router-link>
                  <router-link class="router-link ml-2" :to="{name: 'EditPatientDetail' , params: { id: row.id } }">
                    <el-tooltip
                      class="item" effect="dark"
                      content="Edit Account"
                      placement="bottom-start"
                      :visible-arrow="false"
                    >
                    <img src="/img/icons/buttons/edit.svg" alt="">
                    </el-tooltip>
                  </router-link>
                </div>
              </el-table-column>

              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                v-bind="column"
                class="text-left manage-patients-table-text"
              >
                <template v-slot="{row}">
                  <template v-if="column.label === 'FIRST NAME'">
                    <router-link class="router-link manage-patients-table-text ml-2" :to="{name: 'PatientDetail' , params: { id: row.id } }">
                      <el-tooltip
                        class="item" effect="dark"
                        :content="hoverPatientInfo(row)"
                        placement="bottom-start"
                        :visible-arrow="false"
                      >
                        <span>{{ row.first_name }}</span>
                      </el-tooltip>
                    </router-link>
                  </template>
                  <template v-else-if="column.label === 'LAST NAME'">
                    <router-link class="router-link manage-patients-table-text ml-2" :to="{name: 'PatientDetail' , params: { id: row.id } }">
                      <el-tooltip
                        class="item" effect="dark"
                        :content="hoverPatientInfo(row)"
                        placement="bottom-start"
                        :visible-arrow="false"
                      >
                        <span>{{ row.last_name }}</span>
                      </el-tooltip>
                    </router-link>
                  </template>
                  <template v-else-if="column.label === 'OPENED APP'" sortable="custom" >
                    {{ row.opened_app}}
                  </template>
                  <template v-else-if="column.label === 'FOOD'">
                    {{ row.food}}
                  </template>
                  <template v-else-if="column.label === 'WEIGHT LOSS/GAIN'" >
                    <i :class="row.weight > 0  ? 'fas fa-arrow-down text-success' : 'fas fa-arrow-up text-danger'"></i>
                    {{ Math.abs(row.weight) }}
                  </template>
                  <template v-else>
                    {{ row[column.prop] }}
                  </template>
                </template>
              </el-table-column>
              <el-table-column min-width="150px" prop="opened_app"  label="OPENED APP" sortable="custom">
                <template slot-scope="scope">
                  {{ scope.row.opened_app }}
                </template>
              </el-table-column>
              <el-table-column min-width="120px" prop="food"  label="FOOD">
                <template slot-scope="scope">
                  {{ scope.row.food }}
                </template>
              </el-table-column>
              <el-table-column min-width="200px" prop="weight"  label="WEIGHT LOSS/GAIN" sortable="custom">
                <template slot-scope="scope">
                  <i :class="scope.row.weight > 0  ? 'fas fa-arrow-down text-success' : 'fas fa-arrow-up text-danger'"></i>
                  {{ Math.abs(scope.row.weight) }}
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 "
          >
            <custom-pagination
              class="pagination-no-border float-right"
              v-model="current_page"
              :per-page="per_page"
              :total="page_total"
            >
            </custom-pagination>
          </div>
        </card>

      </div>
    </div>
    <div class="tempClassUCFS">
      <user-category-sidebar
      :show-stage-and-surgery-filter="true"
      :stages="stages"
      :surgery-types="types_of_surgery"
      :show-surgery-date-filter="true"
      />
    </div>
  </div>
</template>
<script>
import {Table, TableColumn, Select, Option} from 'element-ui';
import {BasePagination} from '@/components';
import GroupMessageDialog from "@/views/Components/Patients/GroupMessageDialog";
import clientPaginationMixin from '@/views/Tables/PaginatedTables/clientPaginationMixin'
import AddPatient from "@/views/Components/Patients/AddPatient";
import moment from "moment";
import swal from 'sweetalert2';
import Helper from "@/util/globalHelpers";
import UserCategorySidebar from "../../Components/UserCategorySidebar";
import CustomPagination from "../../Components/Pagination/CustomPagination";
import authHelper from "@/util/authHelper";

export default {
  mixins: [clientPaginationMixin],
  components: {
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    GroupMessageDialog: GroupMessageDialog,
    AddPatient: AddPatient,UserCategorySidebar,
    CustomPagination
  },
  data() {
    return {
      response:{
        categories: [],
        defaultCategories: [],
      },
      patientTags: [],
      addPatientLoader: false,
      loaders:{
        exportModalLoader:false,
        categories:false,
        tableLoader : false
      },
      modals : {
        exportPatientsModal:false,
      },
      current_stage: 1,
      tableColumns: [
        {
          prop: 'first_name',
          label: 'FIRST NAME',
          minWidth: 160,
        },
        {
          prop: 'last_name',
          label: 'LAST NAME',
          minWidth: 220,
        },
        {
          prop: 'stage',
          label: 'STAGE',
          minWidth: 250,
        },
        // {
        //   prop: 'opened_app',
        //   label: 'OPENED APP',
        //   minWidth: 150,
        // },
        // {
        //   prop: 'food',
        //   label: 'FOOD',
        //   minWidth: 150,
        // },
        // {
        //   prop: 'weight',
        //   label: 'WEIGHT LOSS/GAIN',
        //   minWidth: 200,
        // },
      ],
      api_param: {
        first_name: null,
        last_name: null,
        email: null,
        mrn_number: null,
        current_stage: null,
        type_of_surgery: null,
        sortBy:'id',
        sortByDir:'DESC',
        surgeryDate: '',
        surgeryDateDuration: ''
      },
      tableData: [],
      selectedRows: [],
      stages: [],
      types_of_surgery: [],
      current_page: 1,
      per_page: 10,
      page_total: 1,
      dialogVisible: false,
      addPatientDialogVisible: false,
      loading: false,
      constant_stages: {
        25: 'Considering Surgery',
        50: 'Pre-Op',
        75: 'Post-Op',
        100: 'Post-Op',
        125: 'Post-Op',
        150: 'Non-Surgical',
      },
      exportPatients: {
        includeName:false
      },
      filtersCount : 0,
      selectedPatientTags: [],
    };
  },
  mounted() {
    this.getFiltersData();
    let vm = this;
    this.$root.$on('UserSubCategoryFilterCount',(data)=>{
      vm.filtersCount = data;
    });
    this.$root.$on('applyUserSubCategoryFilters',(data)=>{
      vm.filtersCount = data.filtersCount;

      vm.selectedPatientTags = data.obj;
      vm.api_param.current_stage = data.currentStage
      vm.api_param.type_of_surgery = data.surgeryType
      vm.api_param.surgeryDate = data.surgeryDate
      vm.api_param.surgeryDateDuration = data.surgeryDateDuration
      vm.current_page = 1;
      vm.getPatientsApiCall(data.obj);
    });
    this.fetchTags();
  },
  beforeDestroy() {
    this.$root.$off('UserSubCategoryFilterCount');
    this.$root.$off('applyUserSubCategoryFilters');
  },
  computed : {
    getFiltersCount () {
      return this.filtersCount ?? 0;
    }
  },
  watch: {
    'current_page': function() {
        this.createCategoriesArrAndApiCall();
    }
  },

  methods: {
    /**
     * Get recently saved API params for search
     */
    getSavedApiParamsForSearch() {
      const token = authHelper.getAuthToken('subUser')
      if (token) {
        const savedSearch = authHelper.getManagePatientsSavedSearch()
        if (savedSearch) {
          this.api_param.first_name = savedSearch.firstName
          this.api_param.last_name = savedSearch.lastName
          this.api_param.email = savedSearch.email
          this.api_param.mrn_number = savedSearch.mrnNumber
          this.api_param.current_stage = savedSearch.currentStage
          this.api_param.type_of_surgery = savedSearch.typeOfSurgery
        }
      }
    },

    getFiltersData(){
      let vm = this;
      vm.loaders.tableLoader = true;
      axios.get(this.$store.getters.getBaseUrl + '/api/pre-load-filters?page=' + 'manage-patients'
      ).then((response) => {
        vm.api_param.current_stage = response.data.data.default_stage;
        vm.stages = response.data.data.stages;
        vm.types_of_surgery = response.data.data.types_of_surgery;
        vm.response.categories = response.data.data.categories;
        vm.addCategoriesToTableHeaders(response.data.data.categories);
        vm.response.defaultCategories = response.data.data.defaultCategories;
        this.$store.commit('setUserSubcategories',{categories:vm.response.categories,defaultCategories:vm.response.defaultCategories })
        let obj = [];
        vm.response.categories.forEach((category,index)=>{
          var i = vm.response.defaultCategories.findIndex(dc=>{
            if(dc.user_category_id == category.id){
              return true;
            }
          });
          if(i!=-1){
            obj.push(vm.response.defaultCategories[i].user_sub_category_id);
          }
        });
        vm.selectedPatientTags = obj;
        vm.getSavedApiParamsForSearch()
        vm.getPatientsApiCall(obj);
      }).catch(error => {
        console.log(error.response)
      });
    },

    openGroupMessageDialog() {
      this.dialogVisible = true;
    },

    openAddPatientDialog() {
      this.addPatientDialogVisible = true;
    },

    patientAdded() {
      this.addPatientDialogVisible = false;
      // if on first page refresh otherwise don't
      if (this.current_page == 1) {
        this.createCategoriesArrAndApiCall();
      }
    },

    filterChanged() {
      this.current_page = 1;
      this.resetAndSaveApiParamsForFutureSearch()
      this.createCategoriesArrAndApiCall();
    },

    /**
     * Reset and save new API params for search
     */
    resetAndSaveApiParamsForFutureSearch() {
      authHelper.deleteManagePatientsSavedSearch()
      const savedSearch = {
        firstName: this.api_param.first_name,
        lastName: this.api_param.last_name,
        email: this.api_param.email,
        mrnNumber: this.api_param.mrn_number,
        currentStage: this.api_param.current_stage,
        typeOfSurgery: this.api_param.type_of_surgery
      }
      authHelper.setManagePatientsSavedSearch(savedSearch)
    },

    clearSurgeryType() {
      this.api_param.type_of_surgery = '';
      this.current_page = 1;
      this.resetAndSaveApiParamsForFutureSearch()
      this.createCategoriesArrAndApiCall();
    },

    clearStage() {
      this.api_param.current_stage = '';
      this.current_page = 1;
      this.resetAndSaveApiParamsForFutureSearch()
      this.createCategoriesArrAndApiCall();
    },

    fetchTags() {
      this.addPatientLoader = true;
      axios.get(`${this.$store.getters.getBaseUrl}/api/user-category/1`)
        .then((response) => {
          this.patientTags = response.data.data
        })
        .catch((error) => console.error(error.response.message))
        .finally()
    },

    createCategoriesArrAndApiCall(){
      let userSubCategoriesIds = [];
      this.response.categories.forEach(category => {
        if(category.state && !isNaN(category.state)){
          userSubCategoriesIds.push(category.state);
        }
      });
      this.selectedPatientTags = userSubCategoriesIds;
      this.getPatientsApiCall(userSubCategoriesIds);
    },

    getPatientsApiCall(userSubCategories = null) {
      let vm = this;
      vm.loaders.tableLoader = true;
      this.$store.commit('setCancellationToken', 'managePatients');
      const config = {
        params: {...this.api_param , user_sub_categories: userSubCategories && userSubCategories.length > 0 ? userSubCategories : null},
        cancelToken: this.$store.state.cancelRequests['managePatients'].token,
      };
      axios.get(this.$store.getters.getBaseUrl + '/api/patients?page=' + this.current_page,
        config
      ).then((response) => {
        vm.tableData = [];
        vm.prepareTableData(response.data.data.patients);

      }).catch(error => {
        if (!axios.isCancel(error)) {
          console.log(error)
        }
      });
    },

    addCategoriesToTableHeaders(userCategories){
      Object.assign(this.$data.tableColumns, this.$options.data().tableColumns);
      var count = 3;
      userCategories.forEach((uc)=>{
        let item = {
          prop: uc.name,
          label: uc.name,
          minWidth: 200,
        };
        this.tableColumns.splice(count,0,item);
        count++;
      });
    },

    hoverPatientInfo(row){
      let returnable = ''
      returnable = (row.hasOwnProperty('first_name') === true) ? returnable+row.first_name : returnable+''
      returnable = (row.hasOwnProperty('last_name') === true) ? returnable+' '+row.last_name : returnable+''
      returnable = (row.hasOwnProperty('email') === true && row.email !== '' && row.email != null) ? returnable+', '+row.email : returnable+''
      returnable = (row.hasOwnProperty('phone') === true && row.phone !== '' && row.phone != null) ? returnable+', '+row.phone : returnable+''
      if (row.hasOwnProperty('dob') === true && row.dob !== '' && row.dob != null){
        if (!isNaN(Date.parse(row.dob))){
          const date = new Date(row.dob)
          returnable = returnable+', '+Helper.extractAndFormatDate(date.toDateString(),false)
        }
      }else {
        returnable = returnable+''
      }
      returnable = (row.hasOwnProperty('mrn') === true && row.mrn !== '' && row.mrn != null) ? returnable + ', ' + row.mrn : returnable + ' '
      return returnable
    },

    prepareTableData(patients) {
      patients.data.forEach((patient, index) => {
        var arr = [];
        arr['id'] = patient.id;
        arr['first_name'] = patient.first_name;
        arr['last_name'] = patient.last_name;
        arr['email'] = patient.email;
        arr['phone'] = patient.phone
        arr['dob'] = patient.dob;
        arr['mrn'] = (patient.mrn_number === null)? '': patient.mrn_number;
        arr['stage'] = this.constant_stages[patient.current_stage];
        arr['opened_app'] = patient.opened_app;
        arr['food'] = patient.food;
        arr['checklists'] = patient.checklists_count;
        arr['foodLogged'] =patient.total_nutrition_meals == 0 ? 0 : patient.meals_last_six_days > 0 ? 1 : 2;
        patient.patient_user_sub_categories.forEach((category, index) => {
          arr[category.user_category_name] = category.user_sub_category_name;
        });
        arr['weight'] = patient.weight.toFixed(2);
        this.tableData.push(arr);
      });
      this.loaders.tableLoader = false;
      this.current_page = patients.current_page;
      this.per_page = patients.per_page;
      this.page_total = patients.total;
    },

    openExportPatientsDialog() {
      this.modals.exportPatientsModal = true;
    },

    onConfirmExportPatientsModal(){
        let vm = this;
        vm.modals.exportPatientsModal = false;
        vm.$store.commit('toggleDownloadReport', true);
        const config = {
          responseType: "blob",
          params: {showId: vm.exportPatients.includeName, ...this.api_param , patient_tags: vm.selectedPatientTags}
        };
        axios.get(`${vm.$store.getters.getBaseUrl}/api/patients-report/excel`,
          config,
        ).then(response => {
          if (vm.$store.state.modals.downloadingReport) {
            const url = URL.createObjectURL(new Blob([response.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            }))
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', ` ${moment().format('MMMM DD, YYYY')} patients.xlsx`)
            document.body.appendChild(link);
            link.click();
            vm.exportPatients.includeName = false;
          }
        }).catch(error => {
        }).finally(() => {
          vm.$store.commit('toggleDownloadReport', false);
        });
    },

    sortMethod({prop,order})  {
      if(order){
        this.api_param.sortBy = prop;
        this.api_param.sortByDir = order == 'ascending' ? 'ASC' : 'DESC';
      }
      else {
        this.api_param.sortBy = "id";
        this.api_param.sortByDir = 'DESC';
      }
      this.current_page = 1;
      this.createCategoriesArrAndApiCall();
    }
  }
};
</script>
<style scoped>
#add-patient-btn {
  padding: 8px 40px;
}

#group-message-btn {
  padding: 8px 15px;
}

.manage-patients-table-text, .manage-patients-table-text * {
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0.48px;
  color: #525F7F!important;
  opacity: 1;
}

.manage-patients-ucf-button-div {
  height: fit-content;
  margin-top: 30px;
}

/* Explicitly showing scroll bar even if gets hide */
.show-hidden-scrollbar >>> .el-table__body-wrapper{
  overflow: auto !important;
}

</style>
<style>

.manage-patient-filter input.form-control{
  height: calc(1.5em + 1.25rem + 2px);
}

.btn-purple {
  color: white;
  background-color: #8898aa;
  border-radius: 9px;
}
.no-border-card .card-footer {
  border-top: 0;
}

.clear_txt {
  text-align: left;
  font: normal normal normal 9px/13px Open Sans;
  letter-spacing: 0px;
  color: #FF6421;
  cursor: pointer;
}

.btn-add-patient, .btn-add-patient:hover, .btn-add-patient:active {
  background: #2F597A 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 2px 8px #32325D26;
  border-radius: 6px;
  border: 1px solid #2F597A !important;
  color: white !important;
}

.el-select-dropdown {
  width: max-content;
}

.checklist-export-modal .modal-body{
  padding-top: 1px;
}
.checklist-export-modal .close > span{
  color: black;
}
.export-message {
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #4D4D4D;
  opacity: 1;
}
.export-label-color{
  color: #172B4D;
}
.show-names{
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #4D4D4D !important;
  opacity: 1;
}

input[type="checkbox"]#show-names:checked{
  color: #FF6421;
  border-color: #FF6421;
  background-color:#FF6421;
}
</style>
